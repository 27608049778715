import React from 'react';
import ReactECharts from "echarts-for-react";
import {inK, lastInArray} from "../../helper.functions";
import {Stack, Typography, useTheme} from "@mui/material";

const getColor = difference => {
    if (difference > 0) return 'green';
    if (difference < 0) return 'red';
    return 'black';
};

const FondsPie = ({fonds}) => {

    const theme = useTheme();

    let results = []
    let options = {
        tooltip: {
            trigger: 'item',
            valueFormatter: (value) => inK(value, false, 1, 'K')
            //value.toFixed(1) + ' %'

        },
        grid: {
            left: '0%',
            right: '0%',
            bottom: '0%',
            containLabel: true,
            top: '2%'
        },
        legend: null,
        series: [
            {
                name: 'Current value',
                type: 'pie',
                radius: ['70%', '100%'],
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 5,
                    borderColor: theme.palette.common.white,
                    borderWidth: 2
                },
                label: {
                    show: true,
                    position: 'inside',
                    backgroundColor: theme.palette.grey[700],
                    padding: 4,
                    borderRadius: 3,
                    color: theme.palette.common.white,
                    borderColor: theme.palette.common.white,
                    borderWidth: 1,
                    formatter: function (params) {
                        return inK(params.value, false, 1, 'K')
                    }
                },

                labelLine: {
                    show: false
                },
                data: null
            },
            {
                name: 'ROI',
                type: 'pie',
                radius: ['40%', '65%'],
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 3,
                    borderColor: theme.palette.common.white,
                    borderWidth: 1,
                },
                tooltip: {
                    trigger: 'item',
                    valueFormatter: (value) => inK(value, true, 1, 'K')
                    //value.toFixed(1) + ' %'

                },

                label: {
                    show: true,
                    position: 'inside',
                    backgroundColor: theme.palette.grey[700],
                    padding: 4,
                    borderRadius: 3,
                    color: theme.palette.common.white,
                    borderColor: theme.palette.common.white,
                    borderWidth: 1,
                    formatter: function (params) {
                        return inK(params.value, false, 1, 'K')
                    }
                },

                labelLine: {
                    show: false
                },
                data: []
            }
        ]
    };

    if (fonds.length) {
        fonds = fonds.filter(item => item.id !== 'ALL')
        options.series[0].data = fonds.map(fond => {
            return {
                value: 1 * lastInArray(fond.values),
                name: fond.title
            }
        })

        let earnings = fonds.map(fond => {
            return {
                value: lastInArray(fond.values) - fond.invested,
                name: fond.title
            }
        })

        let aboveZero = earnings.filter(i => i.value > 0);

        if (aboveZero.length === 0) {
            options.series[1].data = null;
        } else {
            options.series[1].data = earnings
        }

        results = options.series[0].data.map(obj1 => {
            const obj2 = earnings.find(obj => obj.name === obj1.name);
            const percentage1 = obj1.value / options.series[0].data.reduce((acc, obj) => acc + obj.value, 0) * 100;
            const percentage2 = obj2 ? obj2.value / earnings.reduce((acc, obj) => acc + obj.value, 0) * 100 : 0;
            const difference = percentage2 - percentage1;
            return {...obj1, difference, percentage1, percentage2};
        });

    }

    return (
        <>
            <ReactECharts
                option={options}
                notMerge={true}
                lazyUpdate={true}
            />
            {results && results.length > 0 ? (

                <Stack direction="column" spacing={1} sx={{
                    mt: 3, width: '100%'
                }}>
                    {/* Table header */}
                    <Stack direction="row"
                           spacing={1}
                           sx={{
                               pb: 1,
                               width: '100%',
                               borderBottomWidth: 1,
                               borderBottomStyle: 'solid',
                               borderBottomColor: theme.palette.grey[200],
                               fontWeight: 'bold'
                           }}>
                        <div style={{flexGrow: 1}}>Title</div>
                        <div style={{flexGrow: 1, textAlign: 'right'}}>Performance</div>
                    </Stack>
                    {results.map((obj, idx) => (
                        <Stack direction="row"
                               key={idx}
                               spacing={1}
                               sx={{
                                   pb: 1,
                                   width: '100%',
                                   borderBottomWidth: 1,
                                   borderBottomStyle: 'solid',
                                   borderBottomColor: theme.palette.grey[200]
                               }}>
                            <div style={{
                                flexGrow: 1,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                            }}>{obj.name}</div>
                            <div style={{flexGrow: 1, textAlign: 'right'}}>
                                <Typography variant="body2"
                                            style={{color: getColor(obj.difference)}}
                                >
                                    {inK(obj.difference * 1_000, true, 2, '%')}
                                </Typography>
                            </div>
                        </Stack>
                    ))}
                </Stack>
            ) : null}

        </>
    );
}

export default FondsPie;

import * as React from 'react';
import {Facebook, GitHub, Google, LockOutlined} from '@mui/icons-material';
import {Typography, Container, Box, Button, Avatar} from '@mui/material';
import {createTheme, ThemeProvider} from '@mui/material/styles';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            Christoph Neymeyr
            {' '}
            {new Date().getFullYear()}
        </Typography>
    );
}

const theme = createTheme();

export default function LoginScreen({setProvider}) {

    async function signIn(e, provider) {
        setProvider(provider)
        e.preventDefault();

    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                        <LockOutlined/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form"  noValidate sx={{mt: 1}}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mb: 3, py: 2}}
                            onClick={(e) => signIn(e, 'google')}
                        >
                            <Google sx={{mr: 2}}/> Google
                        </Button>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mb: 3, py: 2}}
                            onClick={(e) => signIn(e, 'github')}
                        >
                            <GitHub sx={{mr: 2}}/> GitHub
                        </Button>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{py: 2}}
                            onClick={(e) => signIn(e, 'facebook')}

                        >
                            <Facebook sx={{mr: 2}}/> Facebook
                        </Button>

                    </Box>
                </Box>
                <Copyright sx={{mt: 8, mb: 4}}/>
            </Container>
        </ThemeProvider>
    );
}

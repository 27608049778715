// @ts-ignore
// @ts-ignore

import {supabase} from "./client"

async function get_list() {
    const {data: all} = await supabase
        .from("List")
        .select("*");

    return all.map(item => {
        item.invested = item.amount * item.price;
        return item;
    });
}

const receive_data = async (start_date, days, fn = null) => {
    let d = new Date(start_date);
    d.setDate(d.getDate() - days);
    let earliest = d.toLocaleString("sv").substring(0, 10);

    const {data, error} = await supabase
        .from("Fonds")
        .select("ts, code, total")
        .gte("ts", earliest)
        .lte("ts", start_date)
        .order("ts");

    let result = {};
    if (data.length) {
        let tmp = {};
        let frequencyList = [];
        for (const item of data) {
            let thisDate = new Date(item.ts);
            let thisDay = fn(thisDate);
            let thisKey = thisDay + "," + item.code;
            if (!frequencyList.includes(thisKey)) {
                frequencyList.push(thisKey);
            }
            tmp[thisKey] = item.total;
        }
        frequencyList.sort();
        //result = dayList;
        for (const frequencyListElement of frequencyList) {
            let [ts, code] = frequencyListElement.split(",");
            if (!result[code]) {
                result[code] = [];
            }

            result[code].push({
                ts,
                amount: tmp[frequencyListElement].toFixed(0),
            });
        }
    }

    return error ? error : result;
};


const query_db = async (days, start_date) => {

    let fn = (thisDate) => thisDate.toLocaleString("sv").substring(0, 10);
    return await receive_data(start_date, days, fn);
};

async function get_assets_list() {
    const {data: all} = await supabase
        .from("Assets")
        .select("*")
        .order('sortOrder')

    return all
}

async function get_assets() {
    return await get_assets_list()
}

// async function get_diary_list() {
//     const {data: all} = await supabase
//         .from("Diary")
//         .select("*")
//         .order('ts', {ascending: false})
//         .order('id', {ascending: false})
//
//     return all
// }

// async function get_diary() {
//     return await get_diary_list()
// }

async function get_data_for_results(days, start_date) {
    let list = await get_list();
    let result = await query_db(days, start_date);
    let totalInvested = list.map(i => i.invested).reduce((sum, x) => sum + x);

    list.push({
        name: "All fonds",
        code: "ALL",
        invested: totalInvested * 1
    });
    list = list.map(fond => {
        if (result[fond.code]) {
            let tmpFond = {
                title: fond.name,
                id: fond.code,
                invested: fond.invested.toFixed(0),
                labels: result[fond.code].map(a => a.ts),
                url: "https://markets.ft.com/data/funds/tearsheet/summary?s=" + fond.code + ":EUR",
                values: result[fond.code].map(a => a.amount),
                changes: [0]
            };
            for (let i = 0; i < tmpFond.values.length - 1; i++) {
                tmpFond.changes.push(
                    Number((((tmpFond.values[i + 1] / tmpFond.values[i]) * 100) - 100).toFixed(3))
                );
            }
            return tmpFond;
        } else {
            return null;
        }
    })

    return list;
}

async function get_data_for_wealth(numberOfDays = 30, asOfDate) {
    if (!asOfDate) {
        asOfDate = new Date().toISOString().substring(0, 10)
    }

    let perDate = new Date(asOfDate);
    perDate.setDate(perDate.getDate() - numberOfDays);
    let earliest = perDate.toLocaleString("sv").substring(0, 10);

    let all = []
    for (let i = 1; i <= 4; i++) {
        const {data: thisAsset} = await supabase
            .from("Wealth")
            .select("assetsId,value,date")
            .gte('date', earliest)
            .lte('date', asOfDate)
            .eq('assetsId', i)
            //   .limit(4 * numberOfDays)// 4 assets
            //   .order('assetsId',{ascending: true})
            .order('date')
        all = [...all, ...thisAsset];
    }
    return all
}

export {
    get_data_for_results,
    get_assets,
    get_data_for_wealth,

}

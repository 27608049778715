import React from 'react';
import ReactECharts from "echarts-for-react";
import {chunkify, defaultXAsisForWealthDiagrams, inK, roundToNearest} from "../../helper.functions";
import {useTheme} from "@mui/material";
import {blue} from "@mui/material/colors";

const WealthSummary = ({wealth, assets, chunkSize}) => {
    let options = {
        title: null, tooltip: {
            trigger: 'axis', axisPointer: {
                type: 'shadow'
            }, formatter: function (params) {
                let tooltipText = params[0].data[0] + '<br>';
                let sum = 0
                for (const element of params) {
                    let seriesName = element.seriesName;
                    let value = element.value[1];
                    sum += value
                    tooltipText += '<div style="display:flex;justify-content:space-between;">';
                    tooltipText += '<span style="display:inline-block;text-align:left;margin-right:5px;">' + element.marker + seriesName + '</span>';
                    tooltipText += '<span style="display:inline-block;text-align:right;">';
                    if (element.seriesType === 'bar') {
                        tooltipText += inK(value * 1_000, true, 1, ' %');
                    } else {
                        tooltipText += inK(value, false, 1, ' K');
                    }
                    tooltipText += '</span>';
                    tooltipText += '</div>';
                }
                tooltipText += '<div style="display:flex;justify-content:space-between;">';
                tooltipText += '<span style="display:inline-block;text-align:left;margin-right:5px;"><strong>Sum</strong></span>';
                tooltipText += '<span style="display:inline-block;text-align:right;"><strong>' + inK(sum, false, 1, ' K') + '</strong></span>';
                tooltipText += '</div>';
                return tooltipText;
            }
        },
        legend: null, grid: {
            left: '0%',
            right: '0%',
            bottom: '0%',
            top: '1%',
            containLabel: true
        },
        xAxis: defaultXAsisForWealthDiagrams,
        yAxis: [{
            type: 'value',
            position: 'left',
            interval: 20_000,
            axisLabel: {
                formatter: (function (value) {
                    return inK(value, false, 0, ' K');
                })
            },
        }, {
            type: 'value',
            position: 'right',
            axisLine: {show: true},
            axisLabel: {
                formatter: value => inK(value * 1_000, true, 1, ' %')
            },
        }],
        series: []
    };

    let dates = []

    let groupedData = wealth
        .filter(item => item.assetsId !== 3)
        .reduce((result, obj) => {
            if (!result[obj.assetsId]) {
                result[obj.assetsId] = [];
            }
            result[obj.assetsId].push([obj.date, obj.value]);
            dates.push(obj.date)
            return result;
        }, [])
        .map(group => chunkify(group, chunkSize))


    const dailySums = groupedData.reduce((acc, curr) => {
        curr.forEach((num, index) => {
            if (!acc[index]) {
                acc[index] = 0;
            }
            acc[index] += num[1];
        });
        return acc;
    }, []);
    //groupedData = groupedData.filter(Boolean)

    const highestFirstDay = groupedData.reduce((acc, curr) => {
        return curr[0][1] > acc ? curr[0][1] : acc;
    }, 0);

    dates = [...new Set(groupedData.find(arr => Array.isArray(arr) && arr.length > 0).map(item => item[0]))];

    const changes = dailySums.map((sum, idx) => {
        if (idx === 0) {
            return [dates[idx], 0]
        }
        const difference = dailySums[idx] - dailySums[idx - 1]
        const percentageDifference = (difference / dailySums[idx - 1]) * 100;
        const roundedPercentage = Math.round(percentageDifference * 100) / 100; // Round to 2 decimal places

        return [dates[idx], roundedPercentage]

    })

    //   options.xAxis.data = dates
    let maxValue = Math.max(...dailySums)
    options.yAxis[0].max = roundToNearest(maxValue, 5_000, true);
    options.yAxis[0].min = roundToNearest(highestFirstDay / 1.25, 5_000, false);
    const theme = useTheme();
    assets
        .filter(asset => asset.id !== 3 /* hide loan */)
        //.reverse()
        .forEach(asset => {
            options.series.push({
                name: asset.title,
                type: 'line',
                areaStyle: {
                    color: blue[options.series.length * 100]
                },
                stack: 'total',
                itemStyle: {
                    borderWidth: 0,
                },
                lineStyle: {
                    color: theme.palette.primary.main
                },
                symbolSize: 10, // Set the size of the data points
                label: {
                    show: false, position: 'insideTop', formatter: function (value) {
                        return inK(value.value, false, 1, ' K');
                    }
                },
                data: groupedData[asset.id],
            })
        })

    options.series.push({
        name: 'Changes',
        type: 'bar',
        yAxisIndex: 1,
        z: 3, // Set a higher z-index to bring the bars to the front
        itemStyle: {
            color: useTheme().palette.primary.main,
            shadowColor: '#91cc75',
            opacity: 1,
            borderColor: theme.palette.grey[700],
            borderWidth: 1,
        }, label: {
            show: false,
            position: 'insideTop',
            formatter: function (value) {
                return inK(value.value * 1_000, false, 1, ' K');
            }
        }, data: changes,
    })

    return (<div>
        <ReactECharts
            option={options}
            notMerge={true}
            lazyUpdate={true}
            style={{'height': '600px'}}
        />


    </div>)

};

export default WealthSummary;

import React, {useCallback, useContext, useEffect, useState} from 'react';
import Container from "../Container/Container";
import WealthSummary from "../WealthSummary/WealthSummary";
import WealthDetail from "../WealthDetail/WealthDetail";
import WealthPie from "../WealthPie/WealthPie";
import {get_assets, get_data_for_wealth} from "../../data.functions";
import {Box, Button, ButtonGroup, CircularProgress, Grid, useTheme} from "@mui/material";
import Cookies from 'js-cookie';
import {daysSeries, inK} from "../../helper.functions";
import {CalendarMonth, DonutLarge, Expand, StackedLineChart} from "@mui/icons-material";
import {RouteContext} from "../../context/RouteContext";
import WealthGrowth from "../WealthGrowth/WealthGrowth";
import WealthCalendar from "../WealthCalendar/WealthCalendar";
import {grey} from "@mui/material/colors";

const Wealth = () => {

    const cookieName = 'wealthDays'
    const [wealthData, setWealthData] = useState(null)
    const [assets, setAssets] = useState([])
    const [daysVisible, setDaysVisible] = useState(parseInt(Cookies.get(cookieName)) || 30)

    async function getAssetsList() {
        let results = await get_assets()
        setAssets(results)
    }

    const getWealthData = useCallback(async perDate => {
        let results = await get_data_for_wealth(daysVisible, perDate)
        setWealthData(results)
    }, [daysVisible]);


    const [chunkSize, setChunkSize] = useState(1)

    const {
        setCurrentTitle, /* setter fn from App.js */
        perDate /* Value from App.js */
    } = useContext(RouteContext);

    /* onMount */
    useEffect(() => {
        getAssetsList().then(() => {
        })

        getWealthData(perDate).then(() => {
        })

    }, [perDate, getWealthData]);

    useEffect(() => {
        setCurrentTitle('Wealth');
    }, [setCurrentTitle]);

    const maxVisibleBars = 15;

    /* watch */
    useEffect(() => {
        getWealthData(perDate).then(() => {
        })
        if (daysVisible > maxVisibleBars) {
            setChunkSize(Math.ceil(daysVisible / maxVisibleBars))
        } else {
            setChunkSize(1)
        }
    }, [getWealthData, perDate, daysVisible]); // Dependency array, useEffect will run whenever count changes

    const changeDaysVisible = (newDaysValue) => {
        Cookies.set(cookieName, newDaysValue, {expires: 360});
        if (newDaysValue > 14) {
            setChunkSize(Math.ceil(newDaysValue / maxVisibleBars))
        }
        setDaysVisible(newDaysValue);
    };

    const {wealthGrown} = useContext(RouteContext);

    const theme = useTheme();

    if (wealthData) {
        return (
            <div className="App">
                <Grid container spacing={3} padding={2}>
                    <Grid item md={12} xs={12}>
                        <ButtonGroup
                            variant="contained"
                            fullWidth
                            disableElevation
                            sx={{border: '1px solid silver'}}
                        >
                            {daysSeries.map((item, i) => <Button
                                    onClick={() => changeDaysVisible(item)}
                                    key={i.toString()}
                                    variant="contained"
                                    sx={{
                                        bgcolor: daysVisible === item ? 'primary' : theme.palette.common.white,
                                        color: daysVisible === item ? theme.palette.primary.contrastText : theme.palette.grey[700],
                                        '&:hover': {backgroundColor: 'primary', color: theme.palette.common.white}
                                    }}
                                >
                                    {item}
                                </Button>
                            )}
                        </ButtonGroup>
                    </Grid>

                    <Grid item md={3} xs={12}>
                        <Container
                            component={<WealthPie
                                wealth={wealthData}
                                assets={assets}
                                daysVisible={daysVisible}
                            />}
                            icon={<DonutLarge color="primary"/>}
                            title={['Overview', daysVisible + ' day' + (daysVisible !== 1 ? 's' : '')]}
                        />
                    </Grid>

                    <Grid item md={9} xs={12}>
                        <Container
                            component={<WealthGrowth
                                wealth={wealthData}
                                assets={assets}
                                chunkSize={chunkSize}
                            />}
                            icon={<Expand color="primary"/>}
                            title={['Net growth', inK(wealthGrown, true, 1, 'K')]}
                        />
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <Container
                            component={<WealthCalendar
                                wealth={wealthData}
                            />}
                            icon={<CalendarMonth color="primary"/>}
                            title={['Calendar',
                                daysVisible + ' day' + (daysVisible !== 1 ? 's' : ''),
                            ]}
                        />
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <Container
                            component={<WealthSummary
                                wealth={wealthData}
                                assets={assets}
                                chunkSize={chunkSize}
                            />}
                            icon={<StackedLineChart color="primary"/>}
                            title={'Development'}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Container
                            sx={{p: 2}}
                            component={<Grid container spacing={2}>
                                {assets.map(item => item.id !== 2 && <Grid key={item.id} item md={6} xs={12}>
                                        <Container
                                            component={<WealthDetail
                                                wealth={wealthData}
                                                idx={item.id}
                                                reference={item.reference}
                                                name={item.title}
                                                chunkSize={chunkSize}
                                            />}

                                            elevation={0}
                                            sx={{
                                                p: 2,
                                                mb: 4,
                                                borderWidth: 1,
                                                borderStyle: 'solid',
                                                borderColor: grey[300],
                                                backgroundColor: grey[50]
                                            }}
                                        />
                                    </Grid>
                                )}
                            </Grid>}
                            icon={<Expand color="primary"/>}
                            title={[
                                'Details',
                                inK(wealthGrown, true, 1, 'K')
                            ]}
                        />
                    </Grid>

                </Grid>
            </div>)
    } else {
        return (
            <Container
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <CircularProgress size={60}/>
                </Box>
            </Container>
        )
    }
}

export default Wealth;

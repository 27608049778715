import React from 'react';

import {Box, Divider, Stack, Typography, useTheme} from "@mui/material";
const Title = ({children, icon}) => {
    let style = useTheme()
    if (!Array.isArray(children)) {
        children = [children]
    }
    return (
        <>
            <Stack
                direction="row"
                alignItems="center"
                gap={1}
                sx={{width: '100%'}}
                justifyContent={children.length > 1 ? "space-between" : "flex-start"}
            >
                {icon && (
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        {icon}
                        <Typography variant="h5"
                                    component="h5"
                                    sx={{ml: 1}}
                                    color={style.palette.primary.main}
                        >{children[0]}</Typography>
                    </Box>
                )}

                {!icon && (
                    <Box sx={{flexGrow: 1, display: 'flex', justifyContent: 'flex-start'}}>
                        <Typography
                            variant="h5"
                            component="h5"
                            color={style.palette.primary.main}
                        >{children[0]}</Typography>
                    </Box>
                )}

                {children.length > 1 && children.slice(1, -1).map((item, i) => (
                    typeof item === 'string' ? (
                            <Box flexGrow={1} display="flex" justifyContent="space-between" key={i}>
                                <Typography
                                    variant="h5"
                                    component="h5"
                                    color={style.palette.primary.main}>
                                    {item}
                                </Typography>
                            </Box>

                        )
                        :
                        <Box key={i}>{item}</Box>
                ))}

                {children.length > 1 && (
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        {typeof children[children.length - 1] === 'string' ? (
                                <Typography
                                    variant="h5"
                                    component="h5"
                                    color={style.palette.primary.main}>
                                    {children[children.length - 1]}
                                </Typography>
                            )
                            :
                            <Box>{children[children.length - 1]}</Box>
                        }
                    </Box>
                )}
            </Stack>
            <Divider sx={{mb: 2, mt: 2}}></Divider>
        </>

    )

}

export default Title;

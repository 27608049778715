import React from 'react';
import Title from "../Title/Title";
import {Paper} from "@mui/material";

const Container = ({title, icon, component, sx, elevation = 4}) => {
    return (
        <Paper
            sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                ...sx
            }}
            elevation={elevation}
        >
            {title && <Title icon={icon}>{title}</Title>}
            {component}
        </Paper>
    )
}

export default Container;

import React, {useContext, useEffect} from 'react';
import ReactECharts from "echarts-for-react";
import {chunkify, defaultXAsisForWealthDiagrams, inK, roundToNearest} from "../../helper.functions";
import {useTheme} from "@mui/material";
import {green, red} from "@mui/material/colors";
import {RouteContext} from "../../context/RouteContext";

const WealthGrowth = ({wealth, chunkSize}) => {

    const theme = useTheme();

    let groupedData = wealth.reduce((acc, curr) => {
        const {value, date} = curr;
        const existingArrIndex = acc.findIndex(arr => arr[0] === date);
        if (existingArrIndex !== -1) {
            acc[existingArrIndex][1] += value;
        } else {
            acc.push([date, value]);
        }
        return acc;
    }, []);

    groupedData.sort((a, b) => new Date(a[0]) - new Date(b[0]));

    groupedData = chunkify(groupedData, chunkSize)

    const minValue = Math.min(...groupedData.map(item => item[1]));
    const maxValue = Math.max(...groupedData.map(item => item[1]));

    let optionsBuilder = {
        title: null,

        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
            formatter: function (params) {
                let tooltipText = params[0].data[0] + '<br>';
                for (const element of params) {
                    let seriesName = element.seriesName;
                    let value = element.value[1];
                    tooltipText += '<div style="display:flex;justify-content:space-between;">';
                    tooltipText += '<span style="display:inline-block;text-align:left;margin-right:5px;">' + element.marker + seriesName + '</span>';
                    tooltipText += '<span style="display:inline-block;text-align:right;">';
                    tooltipText += inK(value, false, 1, ' K');
                    tooltipText += '</span>';
                    tooltipText += '</div>';
                }

                return tooltipText;
            }
        },
        legend: null, grid: {
            left: '0%',
            right: '0%',
            bottom: '0%',
            top: '1%',
            containLabel: true
        },
        xAxis: defaultXAsisForWealthDiagrams,
        yAxis: {
            type: 'value',
            position: 'left',
            interval: Math.ceil(((Math.ceil(maxValue / 500) * 500) - (Math.floor(minValue / 500) * 500)) / 10),
            axisLabel: {
                formatter: (function (value) {
                    return inK(value, false, 1, ' K');
                })
            },
            min: roundToNearest(minValue, 500, false),
            max: roundToNearest(maxValue, 500, true),
        },

        series: [{
            name: 'Growth',
            type: 'line',
            lineStyle: {
                color: theme.palette.grey[400]
            },
            symbolSize: 15, // Set the size of the data points
            label: {
                show: false, position: 'insideTop', formatter: function (value) {
                    return inK(value.value, false, 1, ' K');
                }
            },
            itemStyle: {
                color: function (params) {
                    return params.value[1] < 0 ? red[500] : green[400]; // Set color based on value
                }
            },
            data: groupedData,
        }]
    };

    const t = {...optionsBuilder}

    const {setWealthGrown} = useContext(RouteContext);
    const markPointsData = [];

    useEffect(() => {
        if (groupedData && groupedData.length) {
            setWealthGrown(groupedData[groupedData.length - 1][1] - groupedData[0][1])
        }

        const step = Math.floor(groupedData.length / 30);
        for (let i = 0; i <= 30; i++) {
            markPointsData.push(groupedData[i * step]);
        }

        t.series[0].markPoint = {
            data: markPointsData
        };
        t.series[0].data = markPointsData

    });

    return (<div>
        <ReactECharts
            option={t}
            notMerge={true}
            lazyUpdate={true}
            style={{'height': '480px'}}
        />


    </div>)

};

export default WealthGrowth;

import React from 'react';
import ReactECharts from "echarts-for-react";
import {chunkify, inK} from "../../helper.functions";
import {blue} from "@mui/material/colors";

const Per100 = ({fonds, chunkSize}) => {

    let yMin = Infinity, yMax = null;
    let series = fonds.map(fond => {

        let data = fond.values.map((v, idx) => {
            let newVal = v / fond.values[0] * 100
            yMin = Math.min(yMin, newVal)
            yMax = Math.max(yMax, newVal)
            return [fond.labels[idx], newVal];
        })

        data = chunkify(data, chunkSize)

        let seriesDefinition = {
            name: fond.title,
            type: 'line',
            smooth: true,
            symbolSize: 7, // Set the size of the data points
            lineStyle: {
                width: 2
            },
            data
        }

        if (fond.id === 'ALL') {

            seriesDefinition = {
                ...seriesDefinition, ...{
                    lineStyle: {
                        color: blue[100],
                        width: 8,
                        type: 'dashed'
                    },
                    symbolBorderColor: blue[100],
                    symbolBorderWidth: 2,
                    symbol: 'circle',
                    symbolSize: 15,
                    itemStyle: {
                        color: blue[50], // Adjust the color of the ring
                        borderColor: blue[600],
                        borderWidth: 2
                    }
                }
            }
        }

        return seriesDefinition
    })

    let options = {
        title: null,
        tooltip: {
            trigger: 'item',
            valueFormatter: (value) => value.toFixed(1) + ' %'
        },
        legend: null,
        grid: {
            left: '0%',
            right: '0%',
            bottom: '0%',
            containLabel: true,
            top: '2%'
        },
        toolbox: null,
        xAxis: {
            type: 'time',
            boundaryGap: false,
            axisLabel: {
                interval: function (index) {
                    let maxLabelsToShow = 10;
                    let interval = 1 + Math.ceil(series[0].data.length / maxLabelsToShow);
                    return index % interval === 0;
                },
                rotate: 90,
            }
        },
        yAxis: {
            type: 'value',
            min: Math.floor(yMin),
            max: Math.ceil(yMax),
            axisLabel: {
                formatter: (function (value) {
                    return inK(value * 1_000, false, 0, ' %');
                })
            }
        },
        series: series
    }

    return (
        <div>
            <ReactECharts
                option={options}
                notMerge={true}
                lazyUpdate={true}
                style={{'height': '400px'}}
            />
        </div>
    )
};

export default Per100;

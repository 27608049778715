import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {Box, Button, ButtonGroup, Container, Link, TextField, Typography} from '@mui/material';
import {get_assets} from "../../data.functions";
import {supabase} from "../../client";
import {green, grey, red} from "@mui/material/colors";
import {inK, to10} from "../../helper.functions";
import {Save} from '@mui/icons-material';
import {closeSnackbar, enqueueSnackbar, SnackbarProvider} from 'notistack'
import {RouteContext} from "../../context/RouteContext";

export default function Settings() {

    const {setCurrentTitle} = useContext(RouteContext);
    useEffect(() => {
        setCurrentTitle('Settings');
    }, [setCurrentTitle]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let today = new Date()
        today = today.toISOString().split('T')[0]
        setOldFondsValue(assets[0].value)

        try {
            let startedSavingSnackbar = enqueueSnackbar('Saving settings...', {
                variant: 'warning',
                persist: false
            });
            for (let i = 0; i < assets.length; i++) {
                const assetsId = assets[i].id;
                const value = assets[i].value;
                await supabase
                    .from('Wealth')
                    .delete()
                    .eq('assetsId', assetsId)
                    .eq('date', today)

                await supabase
                    .from('Assets')
                    .update({
                        value
                    })
                    .eq('id', assetsId)

                await supabase
                    .from('Wealth')
                    .insert([{
                        assetsId,
                        value,
                        date: today
                    }]);
            }
            closeSnackbar(startedSavingSnackbar)
            enqueueSnackbar('Settings saved!', {
                variant: 'success',
                persist: false
            });

        } catch (error) {
            console.error('Error submitting data:', error);
        }
    };

    const [assets, setAssets] = useState([])
    const [oldFondsValue, setOldFondsValue] = useState(0);
    useEffect(() => {
        // declare the async data fetching function
        const fetchData = async () => {
            let m = await get_assets()
            setAssets(m)
            setOldFondsValue(m[0].value)
        };

        fetchData().then(() => {
        })

    }, [])


    const addToFunds = [-10, -50, -250, 10, 50, 250]

    const handleButtonClick = (value) => {
        const newAssetsList = [...assets];
        let newValue = parseInt(newAssetsList[0].value)
        const remainder = newValue % 10;
        newValue += parseInt(value) - remainder;
        if (remainder >= 5) {
            newValue += 10
        }
        newAssetsList[0].value = newValue;
        setAssets(newAssetsList)
        enqueueSnackbar(`Changed value: ${to10(value, true)}`, {
            variant: 'info',
            persist: false,
            autoHideDuration: 1_000
        });
    };

    const handleChange = (index) => (event) => {
        const newAssetsList = [...assets];
        newAssetsList[index].value = event.target.value;
        setAssets(newAssetsList);
    };

    return (
        <>
            <Container component="main" maxWidth="xs">
                <SnackbarProvider
                    anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Settings
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                        {
                            assets.map((item, i) => (
                                <div key={item.id}>
                                    <TextField
                                        margin="normal"
                                        type='tel'
                                        required
                                        fullWidth
                                        label={item.title}
                                        autoFocus
                                        value={item.value}
                                        onChange={handleChange(i)}
                                    />

                                    {i === 0 && (
                                        <>
                                            <ButtonGroup
                                                fullWidth={true}
                                                sx={{
                                                    mb: 1
                                                }}
                                            >
                                                >
                                                {addToFunds.map((value, idx) => (
                                                    <Button key={`positive_${idx}`}
                                                            sx={{
                                                                borderColor: green[800],
                                                                color: value > 0 ? green[800] : red[800],
                                                                touchAction: 'manipulation',
                                                            }}
                                                            onClick={() => handleButtonClick(value)}>
                                                        {inK(value * 1_000, true, 0, '')}
                                                    </Button>
                                                ))}
                                            </ButtonGroup>

                                            {item.value - oldFondsValue !== 0 && (
                                                <Typography
                                                    color={grey[500]}>
                                                    Was: {to10(oldFondsValue, false)} €,
                                                    changed: <strong>
                                                    {to10((item.value - oldFondsValue), true)} €
                                                </strong> &raquo; <Link
                                                    style={{cursor: 'pointer'}}
                                                    onClick={() => handleButtonClick(-(item.value - oldFondsValue))}>reset</Link>
                                                </Typography>
                                            )}
                                        </>
                                    )}
                                </div>
                            ))
                        }
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{my: 3}}
                            startIcon={<Save/>}
                        >
                            <Typography sx={{ml: 1}} variant="body1">Save</Typography>
                        </Button>

                        <Link
                            href="https://ywfeegbtpnxdvkzxywrx.supabase.co/storage/v1/object/public/lunch/screenshot.png"
                            target="_blank"
                            rel="noopener noreferrer"
                            variant="body1"
                            color="primary"
                            sx={{mt: 4}}
                        >
                            View latest screenshot
                        </Link>
                    </Box>
                </Box>
            </Container>
        </>
    );
}

import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {
    Box,
    Button,
    Container,
    Dialog, DialogActions, DialogContent,
    DialogTitle, Divider,
    Paper, Stack,
    TextField,
    Typography
} from '@mui/material';
import {supabase} from "../../client";
import {closeSnackbar, enqueueSnackbar, SnackbarProvider} from 'notistack';
import {RouteContext} from "../../context/RouteContext";
import {Delete, Edit} from '@mui/icons-material';
import {grey} from "@mui/material/colors";

export default function Diary() {

    const {setCurrentTitle} = useContext(RouteContext);
    useEffect(() => {
        setCurrentTitle('Diary');
    }, [setCurrentTitle]);

    const [diary, setDiary] = useState([]);
    const [content, setContent] = useState('');
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [entryToDelete, setEntryToDelete] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [entryToEdit, setEntryToEdit] = useState(null);
    const [editedContent, setEditedContent] = useState('');

    const handleDelete = async () => {
        if (entryToDelete) {
            const {error} = await supabase.from('Diary').delete().eq('id', entryToDelete.id);
            if (error) {
                console.error('Error deleting entry:', error);
            } else {
                const updatedEntries = diary.filter(entry => entry.id !== entryToDelete.id);
                setDiary(updatedEntries);
                setDeleteDialogOpen(false);
                setEntryToDelete(null);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let today = new Date().toISOString().split('T')[0];

        try {
            let startedSavingSnackbar = enqueueSnackbar('Saving diary entry...', {
                variant: 'warning',
                persist: false
            });

            if (content.trim()) {
                const newEntry = {
                    ts: today,
                    content,
                };
                let result = await supabase
                    .from('Diary')
                    .insert(newEntry)
                    .select();

                setDiary([...diary, result.data[0]]);
                setContent('');
            }

            closeSnackbar(startedSavingSnackbar);
            enqueueSnackbar('Diary entry saved!', {
                variant: 'success',
                persist: false
            });

        } catch (error) {
            console.error('Error submitting data:', error);
        }
    };

    const handleUpdate = async () => {
        if (entryToEdit) {
            const {error} = await supabase
                .from('Diary')
                .update({content: editedContent})
                .eq('id', entryToEdit.id);

            if (error) {
                console.error('Error updating entry:', error);
            } else {
                const updatedEntries = diary.map(entry =>
                    entry.id === entryToEdit.id ? {...entry, content: editedContent} : entry
                );
                setDiary(updatedEntries);
                setEditDialogOpen(false);
                setEntryToEdit(null);
                setEditedContent('');
            }
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            let {data, error} = await supabase.from('Diary').select('*');
            if (error) {
                console.error('Error fetching data:', error);
            } else {
                setDiary(data);
            }
        };

        fetchData();
    }, []);

    const openDeleteDialog = (entry) => {
        setEntryToDelete(entry);
        setDeleteDialogOpen(true);
    };

    const openEditDialog = (entry) => {
        setEntryToEdit(entry);
        setEditedContent(entry.content);
        setEditDialogOpen(true);
    };

    const closeDeleteDialog = () => {
        setDeleteDialogOpen(false);
        setEntryToDelete(null);
    };

    const closeEditDialog = () => {
        setEditDialogOpen(false);
        setEntryToEdit(null);
        setEditedContent('');
    };

    return (
        <>
            <Container component="main" maxWidth="sm">
                <SnackbarProvider
                    anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Diary
                    </Typography>
                    {
                        diary.map((entry) => (
                            <Paper key={entry.id}
                                   elevation={0}
                                   sx={{
                                       padding: 1,
                                       mt: 4,
                                       width: "100%",
                                       borderWidth: 1,
                                       borderStyle: 'solid',
                                       borderColor: grey[300]
                                   }}
                            >

                                <Box sx={{width: '100%', mb: 1}}>
                                    <Stack direction="row" justifyContent="space-between" alignItems="center"
                                           spacing={1}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            startIcon={<Edit/>}
                                            size="small"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                openEditDialog(entry);
                                            }}
                                        >
                                            Edit
                                        </Button>
                                        <Typography variant="h6" color="primary"
                                                    sx={{textAlign: 'center', flexGrow: 1}}>
                                            {entry.ts}
                                        </Typography>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            color="error"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                openDeleteDialog(entry);
                                            }}
                                            startIcon={<Delete/>}
                                        >
                                            Delete
                                        </Button>
                                    </Stack>
                                </Box>
                                <Divider sx={{mb: 1}}/>
                                <Typography variant="body1">
                                    {entry.content.split('\n').map((line, index) => (
                                        <React.Fragment key={index}>
                                            {line}
                                            <br/>
                                        </React.Fragment>
                                    ))}
                                </Typography>
                            </Paper>
                        ))
                    }

                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 4, width: "100%"}}>
                        <TextField
                            label="New Entry"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                            style={{marginBottom: '1rem'}}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{my: 3}}
                        >
                            <Typography sx={{ml: 1}} variant="body1">Save</Typography>
                        </Button>
                    </Box>
                </Box>

                <Dialog open={deleteDialogOpen} onClose={closeDeleteDialog}>
                    <DialogTitle>Delete Entry</DialogTitle>
                    <DialogContent sx={{width: 300}}>
                        <Typography variant="body1">
                            Are you sure you want to delete this entry?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDeleteDialog} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleDelete} color="primary">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={editDialogOpen} onClose={closeEditDialog}>
                    <DialogTitle>Edit Entry</DialogTitle>
                    <DialogContent sx={{width: 480}}>
                        <TextField
                            label="Edit Entry"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            value={editedContent}
                            onChange={(e) => setEditedContent(e.target.value)}
                            style={{marginTop: 10, mb: 6, width: "100%"}}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeEditDialog} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleUpdate} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </>
    );
}

import React, {useEffect, useState} from 'react';
import Title from "../Title/Title";
import {Stack, Typography} from "@mui/material";
import {calculateEstimate, inK, lastInArray} from "../../helper.functions";

const Estimates = ({fond}) => {
    const [perspectiveList, setPerspective] = useState([]);

    let last = lastInArray(fond.values)

    useEffect(() => {
        let lastValue = lastInArray(fond.values)
        let valueChange = 100 * ((lastValue / fond.values[0]) - 1) / 30

        let perspectiveList = [30, 60, 90, 180, 360].map(days => {
            let perspectiveValue = calculateEstimate(last, valueChange, days)
            let diff = perspectiveValue - lastValue
            return {
                days, perspectiveValue, diff
            }
        })
        setPerspective(perspectiveList)
    }, [fond, last])

    return (
        <>
            <Title>Estimates</Title>
            {perspectiveList.map(
                (item) =>
                    <Stack direction="row"
                           spacing={1}
                           justifyContent="space-between"
                           sx={{mb: 1}}
                    >
                        <Typography variant="body1">{item.days} days:</Typography>
                        <Typography variant="body1">{
                            inK(item.perspectiveValue, false, 1, 'K')
                        }</Typography>
                        <Typography variant="body1">{
                            inK(item.diff, true, 1, 'K')
                        }</Typography>
                    </Stack>
            )}
        </>
    );
}

export default Estimates;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {RouteProvider} from "./context/RouteContext";

const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = createTheme({
    palette: {
        primary: {
            main: '#003479'
        }
    }
});

root.render(
    <BrowserRouter>
        <ThemeProvider theme={theme}>
            <RouteProvider>
                    <App/>
            </RouteProvider>
        </ThemeProvider>
    </BrowserRouter>
);

import React from 'react';
import {Avatar, Stack, Typography} from "@mui/material";
import {inK, to10} from "../../helper.functions";
import {ArrowDownward, ArrowForward, ArrowUpward} from "@mui/icons-material";

const MetaBar = ({meta, valueChange}) => {
    return (
        <Stack direction="row"
               spacing={0}
               justifyContent="space-between"
               sx={{mt: 4}}
        >
            <Stack direction="row" alignItems="center" gap={1}>
                <Typography variant="body2">T:</Typography>
                <Avatar sx={{
                    width: 16,
                    height: 16,
                    bgcolor: meta.total.color
                }}> </Avatar>
                <Typography variant="body2">{to10(meta.total.euro, true)} &euro;</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" gap={1}>

                {meta.total.euro > 0 ? (<ArrowUpward/>) : meta.total.euro < 0 ? (
                    <ArrowDownward/>) : (<ArrowForward/>)
                }
                <Typography variant="body2">{inK(meta.total.percent * 1_000, true, 1, ' %')}</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" gap={1}>
                <Typography variant="body2">P:</Typography>
                {valueChange > 0 ? (<ArrowUpward/>) : valueChange < 0 ? (
                    <ArrowDownward/>) : (<ArrowForward/>)
                }
                <Typography variant="body2">{inK(valueChange * 1_000, true, 1, ' %')}</Typography>
            </Stack>
        </Stack>
    );
}

export default MetaBar;

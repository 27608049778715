import React from 'react';
import {BottomNavigation, BottomNavigationAction, Box, Link, Paper, useTheme} from "@mui/material";
import {yellow} from "@mui/material/colors";
import {styled} from "@mui/material/styles";
import {AttachMoney, BarChart, EditCalendar, Settings} from "@mui/icons-material";
import {useNavigate, useLocation} from "react-router-dom";

const Navigation = () => {
    const navigate = useNavigate();

    const theme = useTheme()

    const TabBarButton = styled(BottomNavigationAction)({
        color: theme.palette.primary.contrastText,
        '&.Mui-selected': {
            color: yellow[700],
            fontWeight: 'bold',
        },
    });
    return (
        <Box sx={{pb: 7}}>
            <Paper sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0
            }} elevation={3}
            >
                <BottomNavigation
                    style={{
                        position: 'fixed',
                        bottom: 0,
                        width: '100%',
                        backgroundColor: theme.palette.primary.main,
                    }}
                    showLabels
                    value={useLocation().pathname}
                    sx={{pb: {md: 0, xs: 2}}}
                >
                    <TabBarButton label="Wealth"
                                  icon={<AttachMoney/>}
                                  component={Link}
                                  onClick={() => navigate('/')}
                                  value="/"
                    />
                    <TabBarButton label="Fonds"
                                  icon={<BarChart/>}
                                  component={Link}
                                  onClick={() => navigate('/fonds')}
                                  value="/fonds"
                    />
                    <TabBarButton label="Settings"
                                  icon={<Settings/>}
                                  component={Link}
                                  onClick={() => navigate('/settings')}
                                  value="/settings"
                    />

                    <TabBarButton label="Diary"
                                  icon={<EditCalendar/>}
                                  component={Link}
                                  onClick={() => navigate('/diary')}
                                  value="/diary"
                    />

                </BottomNavigation>
            </Paper>
        </Box>
    );
}

export default Navigation;

import * as React from 'react';
import {styled} from '@mui/material/styles';
import {
    Avatar,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Collapse,
    Divider,
    Link,
    Typography, useMediaQuery,
    useTheme
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {blueGrey, green, grey, red} from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {chunkify, inK, lastInArray, to10} from "../../helper.functions";
import ReactECharts from "echarts-for-react";
import MetaBar from "../MetaBar/MetaBar";
import Estimates from "../Estimates/Estimates";


const ExpandMore = styled((props) => {
    const {expand, ...other} = props;
    return <IconButton {...other} />;
})(({theme, expand}) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const FondCard = ({fondObject, days, percentageGrows, chunkSize}) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


    let last = lastInArray(fondObject.values)
    let valueChange = 100 * ((last / fondObject.values[0]) - 1) / 30

    let meta = {
        diff: {
            euro: fondObject.values.length >= 2 ? last - fondObject.values[fondObject.values.length - 2] : 0,
            percent: lastInArray(fondObject.changes)
        },
        total: {
            euro: last - fondObject.invested,
            percent: (last / fondObject.invested * 100) - 100
        }
    }

    meta.diff.color = (meta.diff.euro > 0) ? green[500] : (meta.diff.euro < 0) ? red[500] : grey[500]
    meta.total.color = (meta.total.euro > 0) ? green[500] : (meta.total.euro < 0) ? red[500] : grey[500]

    let scaleFactor = 200;

    let current_performance_euro = (last - fondObject.values[0]) / fondObject.values.length;
    let current_performance = [];
    let expected_performance = [];

    let expected_change = (fondObject.values[0] * percentageGrows * days / 100 / 30)

    for (let i = 0; i < fondObject.labels.length; i++) {
        current_performance.push(
            parseFloat(fondObject.values[0]) + parseFloat(i * current_performance_euro),
        )
        expected_performance.push(
            parseFloat(fondObject.values[0]) + parseFloat(i * expected_change),
        )
    }

    let finalData = [
        fondObject.values,
        fondObject.changes,
        current_performance,
        expected_performance
    ].map(serie => {
        return chunkify(
            serie.map((v, i) => [fondObject.labels[i], 1 * v]),
            chunkSize
        )
    })

    let options = {
        title: null,
        grid: {
            left: '0%',
            right: '0%',
            bottom: '0%',
            containLabel: true,
            top: '2%'
        },
        tooltip: {
            trigger: 'axis',
            valueFormatter: (value) => {
                if (value < 100) {
                    return inK(value * 1_000, true, 2, ' %')
                }
                return inK(value, false, 1, 'K')
            }
        },
        toolbox: null,
        xAxis: {
            type: 'time',
            boundaryGap: false,
            axisLabel: {
                interval: function (index) {
                    let maxLabelsToShow = 10;
                    let interval = 1 + Math.ceil(fondObject.values.length / maxLabelsToShow);
                    return index % interval === 0;
                },
                rotate: 90,
            }
        },
        yAxis: [{
            type: 'value',
            axisLabel: {
                formatter: value => inK(value, false, 1, 'K')
            },
            axisPointer: {
                snap: true
            },
            min: (Math.round(
                (Math.min(...fondObject.values)) / scaleFactor
            ) * scaleFactor) - scaleFactor,

            max: (Math.round(
                (Math.max(...fondObject.values)) / scaleFactor
            ) * scaleFactor) + scaleFactor

        }, {
            type: 'value',
            axisLabel: {
                formatter: value => inK(value * 1_000, true, 1, ' %')
            },
            axisPointer: {
                snap: true
            }
        }],

        series: [
            {
                name: 'Value',
                type: 'line',
                smooth: true,
                data: finalData[0],
                yAxisIndex: 0,
                lineStyle: {
                    color: useTheme().palette.primary.main,
                    width: 6,
                },
            }, {
                name: 'Changes',
                type: 'bar',
                smooth: true,
                data: finalData[1],
                yAxisIndex: 1,
                itemStyle: {
                    color: useTheme().palette.primary.main,
                    shadowColor: '#91cc75',
                    borderType: 'dashed',
                    opacity: 0.3
                }
            }, {
                name: 'Current performance',
                type: 'line',
                smooth: true,
                data: finalData[2],
                yAxisIndex: 0,
            }, {
                name: 'Expected performance',
                type: 'line',
                smooth: true,
                data: finalData[3],
                yAxisIndex: 0,
            }
        ]
    };

    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (

        <Card sx={{
            boxShadow: 0,
            borderRadius: 0,
            p: 0,
            mb: 2,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: grey[300],
            backgroundColor: grey[50]
        }}
        >

            <CardHeader
                sx={{backgroundColor: blueGrey[50]}}
                avatar={
                    <Avatar sx={{bgcolor: meta.diff.color}}>
                        <Typography fontSize={isMobile ? 10: 12}>{to10(meta.diff.euro, true)}</Typography></Avatar>
                }
                title={
                    <Link href={fondObject.url} target="_blank" rel="noopener">
                        {fondObject.title}</Link>
                }
                titleTypographyProps={{
                    variant: 'h6',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    width: 'calc(96%)',
                    whiteSpace: 'nowrap'
                }}
                subheader={
                    <div>
                        <strong>{inK(last, false, 1, ' K')}</strong>
                        &nbsp;// <span>{to10(meta.diff.euro, true)} €</span>
                        &nbsp;// <span>{inK(meta.diff.percent * 1_000, true, 1, ' %')}</span>
                    </div>
                }

            />
            <Divider sx={{mb: 4}}></Divider>

            <CardContent>
                <ReactECharts
                    option={options}
                    notMerge={true}
                    lazyUpdate={true}
                    style={{'height': '300px'}}
                />
                <MetaBar meta={meta} valueChange={valueChange}/>
            </CardContent>
            <CardActions disableSpacing>
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                >
                    <ExpandMoreIcon/>
                </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Estimates fond={fondObject}/>
                </CardContent>
            </Collapse>
        </Card>
    )
        ;
}

export default FondCard

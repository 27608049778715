import React from 'react';
import ReactECharts from "echarts-for-react";
import {chunkify, defaultXAsisForWealthDiagrams, inK, roundToNearest, to10} from "../../helper.functions";
import {Grid, Stack, Typography, useTheme} from "@mui/material";
import {Difference, EuroSymbol} from "@mui/icons-material";
import {blueGrey} from "@mui/material/colors";
import Title from "../Title/Title";

const WealthDetail = ({wealth, idx, name, chunkSize}) => {

        const theme = useTheme();

        const originData = JSON.parse(JSON.stringify(wealth))

        let seriesData = originData
            .filter(item => item.assetsId === idx)
            .map(i =>  [i.date,Math.abs(i.value)])

        seriesData = chunkify(seriesData, chunkSize);
        const minVal = seriesData.reduce((acc, i) => {
            return i[1] < acc ? i[1] : acc;
        }, Infinity);

        const maxVal = seriesData.reduce((acc, i) => {
            return i[1] > acc ? i[1] : acc;
        }, 0);

        let latest = seriesData[seriesData.length - 1][1]

        let changes = latest - seriesData[0][1]

        let changesPercentage = (((latest + changes) / latest) - 1)

        let options = {
            title: null,
            tooltip: {
                trigger: 'item',
                axisPointer: {
                    type: 'shadow'
                },
                formatter: function (params) {
                    const displayValue = inK(params.value[1], false, 1, params.seriesType === 'bar' ? ' %' : ' K');
                    return [
                        `${params.data[0]}<br>`,
                        '<div style="display:flex;justify-content:space-between;">',
                        `<span style="display:inline-block;text-align:left;margin-right:5px;">${params.marker} ${params.seriesName}</span>`,
                        `<span style="display:inline-block;text-align:right;font-weight: bold">${displayValue}</span>`,
                        '</div>'
                    ].join('');
                }
            }, legend: null,
            grid: {
                left: '0%',
                right: '0%',
                bottom: '0%',
                top: '1%',
                containLabel: true
            },
            xAxis: defaultXAsisForWealthDiagrams,

            yAxis: {
                interval: Math.ceil(((Math.ceil(maxVal / 500) * 500) - (Math.floor(minVal / 500) * 500)) / 10),
                type: 'value',
                axisLabel: {
                    formatter: (function (value) {
                        //if (index === 0 || index === array.length - 1 || index >= array.length - 5 || array.length
                        // <= 20) { // Include first, last, last 5 labels, or if less than or equal to 20 items
                        return inK(value, false, 1, ' K');
                        //}
                        //return null;
                    })
                },
                max: roundToNearest(maxVal, 1000, true),
                min: roundToNearest(minVal, 1000, false)
            },
            series: [
                {
                    name,
                    type: 'line',
                    smooth: true,
                    lineStyle: {
                        width: 4,
                        type: ''
                    },
                    itemStyle: {
                        borderColor: theme.palette.common.white,
                        borderWidth: 1
                    },
                    symbolSize: 10, // Set the size of the data points
                    areaStyle: {
                        color: blueGrey[100]
                    },
                    label: {
                        show: false,
                        position: 'insideTop',
                        formatter: function (value) {
                            if (value.dataIndex % 2 === 1) {
                                return inK(value.value, false, 1, ' K');
                            }
                            return ''
                        }
                    },
                    data: seriesData,
                }
            ]
        }


        let style = useTheme()


        return (<div>
            <Title children={[name, to10(latest, false) + " €"]}/>
            <Grid container mb={4} color={style.palette.primary.main}>
                <Grid item xs={6}>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <Difference/>
                        <Typography variant="h6" align="center">
                            {inK(changesPercentage * 100 * 1_000, true, 2, ' %')}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={1}>
                        <EuroSymbol/>
                        <Typography variant="h6" align="right">
                            {to10(changes, true)} €
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
            <ReactECharts
                option={options}
                notMerge={true}
                lazyUpdate={true}
                style={{'height': '400px'}}
            />
        </div>)
    }
;

export default WealthDetail;

function inK(n, hasSign = true, decimals = 2, suffix = '') {
    if (isNaN(n)) {
        n = 0
    }

    n = n / 1_000;
    // let p = Math.pow(10, decimals)/1000

    let prefix = '';
    if (hasSign) {
        prefix = n > 0 ? '+' : '';
    }

    return prefix + n.toFixed(decimals) + suffix
}

function to10(n, withSign) {
    if (isNaN(n)) {
        n = 0
    }
    let result = (Math.round(n / 10) * 10).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    if (n > 0 && withSign) {
        return '+' + result;
    }
    return result
}

function lastInArray(a) {
    return (a.length) ? a[a.length - 1] : null
}

function chunkify(arr, chunkSize) {
    const chunkedArray = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        const average = chunk.reduce((sum, num) => sum + num[1], 0) / chunk.length;
        chunkedArray.push([arr[i][0], average]);
    }
    return chunkedArray
}

function calculateEstimate(value, percentage, days) {
    return value * Math.pow((100 + (percentage * 253 / 365)) / 100, days)

}

const daysSeries = [1, 3, 7, 14, 30, 60, 90, 180, 360]

const defaultXAsisForWealthDiagrams = {
    type: 'time',
    axisLabel: {
        formatter: function (value) {
            const date = new Date(value);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        rotate: 90,
        interval: 4
    }
}

function roundToNearest(number, interval, roundUp) {
    return Math[roundUp ? 'ceil' : 'floor'](number / interval) * interval;
}

export {inK, to10, lastInArray, chunkify, daysSeries, calculateEstimate, roundToNearest, defaultXAsisForWealthDiagrams}

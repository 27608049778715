import React from 'react';
import ReactECharts from "echarts-for-react";
import {daysSeries, inK, to10} from "../../helper.functions";
import {Grid, Stack, Typography, useTheme} from "@mui/material";
import {blue} from "@mui/material/colors";

const WealthPie = ({wealth, assets, daysVisible}) => {

    const groupedData = wealth.filter(item => item.assetsId !== 3).reduce((result, obj) => {
        if (!result[obj.assetsId]) {
            result[obj.assetsId] = [];
        }
        result[obj.assetsId].push([obj.date, obj.value]);
        return result;
    }, []);

    let pieData = groupedData.reduce((acc, segment, idx) => {
        if (segment[segment.length - 1][1]) {
            acc.push({
                value: segment[segment.length - 1][1],
                name: assets.find(asset => asset.id === idx).title,
                itemStyle: {color: blue[acc.length * 100]}
            });
        }
        return acc;
    }, []);


    pieData = Object.values({...pieData})
    const sum = pieData.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);
    const changed = groupedData.map(segment => segment[0][1]).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const diff = sum - changed
    const changePercentage = ((sum / changed) * 100) - 100
    const theme = useTheme();

    let optionsPie = {
        tooltip: {
            trigger: 'item', valueFormatter: (value) => inK(value, false, 1, 'K')
            //value.toFixed(1) + ' %'
        }, grid: {
            left: '0%', right: '0%', bottom: '0%', containLabel: true, top: '2%'
        }, legend: null, series: [{
            name: 'Current value',
            type: 'pie',
            radius: ['70%', '100%'],
            avoidLabelOverlap: false,
            itemStyle: {
                borderRadius: 5,
                borderColor: theme.palette.common.white,
                borderWidth: 2
            }, label: {
                show: true,
                position: 'inside',
                backgroundColor: theme.palette.grey[700],
                padding: 4,
                borderRadius: 3,
                color: theme.palette.common.white,
                borderColor: theme.palette.common.white,
                borderWidth: 1,
                formatter: function (params) {
                    return inK(params.value, false, 1, 'K')
                }
            },

            labelLine: {
                show: false
            }, data: pieData
        },

        ], graphic: [{
            type: 'text',
            left: 'center',
            top: '47.5%',
            style: {
                text: to10(sum, false),
                textAlign: 'center',
                fontSize: 30,
                fontWeight: 'bold'
            }
        }, {
            type: 'text',
            left: 'center',
            top: '60%',
            style: {
                text: to10(diff, true),
                fill: theme.palette.grey[800],
                fontSize: 28,
            },
        }, {
            type: 'text',
            left: 'center',
            top: '35%',
            style: {
                text: inK(changePercentage * 1_000, true, 2, ' %'),
                fill: theme.palette.grey[800],
                fontSize: 28,
            },
        }
        ]
    }

    let changePercentagePerDay = changePercentage / daysVisible / 100
    let changes = daysSeries.map(days => {
        let estimatedValue = sum * Math.pow(1 + changePercentagePerDay, days)
        return [days, estimatedValue]
    })

    return (<div>

        <ReactECharts
            option={optionsPie}
            notMerge={true}
            lazyUpdate={true}
        />


        <Grid container spacing={2}>
            <Grid item xs={12}>
                {changes.map((item, columnIndex) => (
                    <Stack direction="row"
                           spacing={1}
                           justifyContent="space-between"
                           sx={{mb: 0}}
                           key={columnIndex}
                    >
                        <Typography variant="body2">{item[0]} day{item[0] !== 1 ? 's' : ''}:</Typography>
                        <Typography variant="body2">
                            <strong>{inK(item[1], false, 1, ' K')}</strong>
                            &nbsp;({
                            inK(item[1] - sum, true, 1, ' K')
                        })
                        </Typography>
                    </Stack>
                ))}
            </Grid>
        </Grid>


    </div>)

};

export default WealthPie;

import React, {useCallback, useContext, useEffect, useState} from 'react';
import {daysSeries, inK, to10} from "../../helper.functions";
import {Button, ButtonGroup, Grid, Slider, Typography, useTheme} from "@mui/material";
import Container from "../Container/Container";
import FondsSummary from "../FondsSummary/FondsSummary";
import FondsPie from "../FondsPie/FondsPie";
import Per100 from "../Per100/Per100";
import FondCard from "../FondCard/FondCard"
import {get_data_for_results} from "../../data.functions";
import Cookies from 'js-cookie';
import {DonutSmall, StackedLineChart} from "@mui/icons-material";
import {RouteContext} from "../../context/RouteContext";

const Fonds = () => {
    const cookieNames = {days: 'fondDays', slider: 'sliderPercentage'}

    const [fondList, setFondList] = useState([]);
    const [daysVisible, setDaysVisible] = useState(parseInt(Cookies.get(cookieNames.days)) || 30)

    const [chunkSize, setChunkSize] = useState(1)

    const maxVisibleBars = 15;

    const {setCurrentTitle, perDate, roiSum, fondsSum, setFondsSum} = useContext(RouteContext);

    const setFondsSumWrapper = useCallback((v) => {
        setFondsSum(v)
    }, [setFondsSum])

    const updateList = useCallback(async (days) => {

        setDaysVisible(days)
        Cookies.set(cookieNames.days, days, {expires: 360});

        let results = await get_data_for_results(days, perDate);
        results = results.filter(item => item !== null)

        let fonds = results.sort(a => {
            return a.id === 'ALL' ? 1 : -1
        })
        setFondList(fonds)

        const all_fonds = fonds.find(fond => fond.id === 'ALL');
        if (all_fonds) {
            setFondsSumWrapper(all_fonds.values[all_fonds.values.length - 1]);
        } else {
            setFondsSumWrapper(0);
        }
    }, [perDate, setFondsSumWrapper, cookieNames.days])

    useEffect(() => {
        setCurrentTitle('Fonds');
    }, [setCurrentTitle]);

    useEffect(() => {
        if (daysVisible > maxVisibleBars) {
            setChunkSize(Math.ceil(daysVisible / maxVisibleBars))
        } else {
            setChunkSize(1)
        }
    }, [daysVisible])

    useEffect(() => {
        updateList(daysVisible).then(() => {
        })
    }, [updateList, perDate, daysVisible]); // Dependency array: useEffect will re-run whenever 'count'
    // changes


    useEffect(() => {
        async function getNewList() {
            await updateList(daysVisible);
        }

        getNewList().then(() => {
        })
        // eslint-disable-next-line
    }, []);

    const [sliderValue, setSliderValue] = useState(1);

    const changeSliderValue = (event, newValue) => {
        Cookies.set(cookieNames.slider, newValue, {expires: 360});
        setSliderValue(newValue);
    };

    const theme = useTheme();

    return (
        <div className="App">
            <Grid container spacing={3} padding={2}>
                <Grid item md={9} xs={12}>
                    <Container
                        component={<FondsSummary fonds={fondList}/>}
                        title={roiSum ? [
                            fondsSum ? inK(fondsSum, false, 1, "K") : null,
                            to10(roiSum.latestChangeEuro, true) + ' €',
                            to10(roiSum.roi_euro, true) + ' €',
                        ] : 'FondsSummary'}
                    />
                </Grid>

                <Grid item md={3} xs={12}>
                    <Container
                        title={'Overview'}
                        component={<FondsPie fonds={fondList}/>}
                        icon={<DonutSmall color="primary"/>}
                    >
                    </Container>
                </Grid>

                <Grid item md={12} xs={12}>
                    <ButtonGroup
                        variant="contained"
                        fullWidth
                        disableElevation
                        sx={{border: '1px solid silver'}}
                    >
                        {daysSeries.map((item, i) => <Button
                                onClick={() => updateList(item)}
                                key={i.toString()}
                                variant="contained"
                                sx={{
                                    bgcolor: item === daysVisible ? 'primary' : theme.palette.common.white,
                                    color: item === daysVisible ? theme.palette.common.white : theme.palette.grey[700],
                                    '&:hover': {backgroundColor: 'primary', color: theme.palette.common.white}
                                }}
                                className={item === daysVisible ? 'active' : null}
                            >
                                {item}
                            </Button>
                        )}

                    </ButtonGroup>
                </Grid>

                <Grid item md={12} xs={12}>
                    <Container
                        component={<Per100
                            fonds={fondList}
                            chunkSize={chunkSize}
                        />}
                        icon={<StackedLineChart color="primary"/>}
                        title={'Performance per 100'}
                    />
                </Grid>

                <Grid item md={12} xs={12}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                            <Typography variant="body1">
                                Estimate in %
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Slider
                                value={sliderValue}
                                color="primary"
                                step={.05}
                                marks
                                valueLabelDisplay="on"
                                onChange={changeSliderValue}
                                valueLabelFormat={(value) => inK(value * 1_000, true, 2, '%')}
                                min={0}
                                max={1.5}
                            />
                        </Grid>
                    </Grid>

                </Grid>

                <Grid item md={12} xs={12}>
                    <Container
                        component={
                            <Grid container spacing={2}>
                                {[...fondList].sort(i => {
                                    return i.id !== 'ALL' ? 1 : -1
                                }).map((fondObject, i) => (
                                        <Grid key={i} item md={6} xs={12}>
                                            <FondCard
                                                fondObject={fondObject}
                                                days={daysVisible}
                                                percentageGrows={sliderValue}
                                                chunkSize={chunkSize}
                                            />
                                        </Grid>
                                    )
                                )}
                            </Grid>
                        }
                        title="Details">
                        ...
                    </Container>
                </Grid>
            </Grid>

        </div>
    )
}

export default Fonds;

import Fonds from "./components/Fonds/Fonds";
import React, {useContext, useEffect, useState} from "react";

import {supabase} from './client';
import LoginScreen from "./components/LoginScreen/LoginScreen";
import {Route, Routes} from "react-router-dom";
import Settings from "./components/Settings/Settings";
import Wealth from "./components/Wealth/Wealth";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {CircularProgress} from "@mui/material";
import Navigation from "./components/Navigation/Navigation";
import Bar from "./components/Bar/Bar";
import {RouteContext} from "./context/RouteContext";
import Diary from "./components/Diary/Diary";

function App() {
    const [user, setUser] = useState(null);
    const [showLoading, setShowLoading] = useState(true);

    useEffect(() => {
            supabase.auth.onAuthStateChange((event, session) => {
                setTimeout(async () => {

                    if (session?.user) {
                        let {data} = await supabase
                            .from("AuthenticatedUsers")
                            .select('name, id')
                            .eq('email', session.user.email)
                            .single()

                        let user = data ? session.user : null
                        if (user) {
                            const provider = session.user.identities.find(item => item.identity_data.provider_id === session.user.user_metadata.provider_id).provider;
                            setShowLoading(false)
                        } else {
                            console.log('no user')
                        }
                        setUser(user)
                    } else {
                        setShowLoading(false)
                        setUser(null)
                    }
                }, 0)
            })

        }, [showLoading]
    )

    async function signIn(provider) {
        /* authenticate with GitHub */
        await supabase.auth.signInWithOAuth({
            provider
        })
    }

    const {currentTitle, setPerDate} = useContext(RouteContext); /* from RouteProvider.js */

    const handleRefresh = async () => {
        return new Promise(() => {
            window.location.reload()
        });
    };

    if (!showLoading) {
        if (user) {
            return (
                <>
                        <Bar user={user} title={currentTitle} onChange={setPerDate}/>
                        <Routes>
                            <Route path="/" element={<Wealth/>}/>
                            <Route path="/fonds" element={<Fonds/>}/>
                            <Route path="/settings" element={<Settings/>}/>
                            <Route path="/diary" element={<Diary/>}/>
                        </Routes>
                    <Navigation/>
                </>
            )
        } else {
            return (
                <>
                    <LoginScreen setProvider={signIn}/>
                </>
            );
        }
    } else {
        return (
            <>
                <Container
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                    }}
                >
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <CircularProgress size={60}/>
                    </Box>
                </Container>
            </>)

    }
}

export default App;

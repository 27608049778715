import React, {useState} from 'react';
import {AppBar, Avatar, Toolbar, Typography} from "@mui/material";
import {BarChart} from "@mui/icons-material";
import DateChooser from "../DateChooser/DateChooser";
import {supabase} from "../../client";

const Bar = ({user, title, onChange, currentDate}) => {
    const [perDate] = useState(currentDate)

    async function signOut() {
        /* sign the user out */
        await supabase.auth.signOut();
    }

    return (
        <AppBar
            position="sticky"
            sx={{backgroundColor: "primary"}}
        >
            <Toolbar>
                <BarChart sx={{mr: 1}}/>
                <Typography
                    variant="h6"
                    component="div"
                    sx={{
                        flexGrow: 1
                    }}>
                    {title}
                </Typography>
                <DateChooser
                    value={perDate}
                    onChange={onChange}
                    sx={{mr: 3}}
                />
                {
                    user ?
                        <Avatar
                            onClick={signOut}
                            src={user.user_metadata.avatar_url}
                        />
                        :
                        ''
                }
            </Toolbar>
        </AppBar>
    );
}

export default Bar

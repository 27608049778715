import React, {useContext, useEffect, useState} from 'react';
import {
    Collapse,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from "@mui/material";
import {inK, lastInArray, to10} from "../../helper.functions";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {blueGrey} from "@mui/material/colors";
import {RouteContext} from "../../context/RouteContext";

const FondsSummary = ({fonds}) => {
    const [list, setList] = useState([]);
    const [isOpen, setOpen] = useState(null);

    const {setRoiSum} = useContext(RouteContext);


    useEffect(() => {
        let theList = fonds.map(fond => {
            fond.current = lastInArray(fond.values)
            fond.roi_euro = fond.current - fond.invested
            fond.roi_percentage = ((fond.current / fond.invested) * 100) - 100
            fond.latestChangeEuro = fond.current - fond.values[fond.values.length - 2]
            fond.latestChangePercentage = ((fond.current / fond.values[fond.values.length - 2]) * 100) - 100
            return fond;
        })
        if (theList.length) {
            setList(
                theList
            )
            setRoiSum(lastInArray(theList))
        }

    }, [fonds, setRoiSum]);

    let theme = useTheme()
    let getColor = (v, comparator) => {
        if (v === comparator || isNaN(v)) {
            return theme.palette.grey.A700;
        }

        return v >= comparator ? theme.palette.success.main : theme.palette.error.main
    }

    const handleClick = (i) => {
        i = (isOpen === i) ? null : i
        setOpen(i);
    };

    return (
        <>
            <TableContainer sx={{display: {xs: 'none', md: 'block'}}}>
                <Table>
                    <TableHead>
                        <TableRow
                            sx={{
                                borderBottom: "2px solid black",
                                "& th": {
                                    fontWeight: "700",
                                    textAlign: "right",
                                }
                            }}>
                            <TableCell sx={{
                                textAlign: "left !important"
                            }}>Title</TableCell>
                            <TableCell>Current value</TableCell>
                            <TableCell>Invested</TableCell>
                            <TableCell>ROI &euro;</TableCell>
                            <TableCell>ROI %</TableCell>
                            <TableCell>Latest &euro;</TableCell>
                            <TableCell>Latest %</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.map(
                            (fond, i) =>
                                <TableRow key={i}
                                          sx={{
                                              "& td": {
                                                  backgroundColor: i === list.length - 1 ? blueGrey[50] : null,
                                                  textAlign: 'right',
                                                  fontWeight: i === list.length - 1 ? 700 : null,
                                              }
                                          }}
                                >
                                    <TableCell sx={{
                                        textAlign: 'left !important',
                                    }}>{fond.title}</TableCell>
                                    <TableCell sx={{color: getColor(fond.current, fond.invested)}}>{
                                        inK(fond.current, false, 1, 'K')
                                    }</TableCell>
                                    <TableCell>{inK(fond.invested, false, 1, 'K')}</TableCell>
                                    <TableCell sx={{color: getColor(fond.roi_euro, 0)}}>{
                                        inK(fond.roi_euro, true, 1, 'K')
                                    }</TableCell>
                                    <TableCell sx={{color: getColor(fond.roi_percentage, 0)}}>{
                                        inK(fond.roi_percentage * 1_000, true, 1, ' %')
                                    }</TableCell>
                                    <TableCell sx={{color: getColor(fond.latestChangeEuro, 0)}}>{
                                        to10(fond.latestChangeEuro, true, 2) + ' €'
                                    }</TableCell>
                                    <TableCell sx={{color: getColor(fond.latestChangePercentage, 0)}}>{
                                        inK(fond.latestChangePercentage * 1_000, true, 1, ' %')
                                    }</TableCell>
                                </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <List sx={{display: {xs: 'block', md: 'none'}}}>
                {list.map((fond, i) => {
                        return (
                            <div key={i}>
                                <ListItemButton onClick={() => {
                                    handleClick(i)
                                }} key={i}>
                                    <ListItemText primary={fond.title}
                                                  secondary={
                                                      <React.Fragment>
                                                          <Typography
                                                              sx={{display: 'inline'}}
                                                              component="span"
                                                              variant="body2"
                                                              color="text.primary"
                                                          >
                                                              {inK(lastInArray(fond.values), false, 1, ' K')}
                                                          </Typography>
                                                          &#47;&#47;
                                                          <Typography
                                                              sx={{display: 'inline'}}
                                                              component="span"
                                                              variant="body2"
                                                              color="text.primary"
                                                          > {to10(fond.latestChangeEuro, true)} € </Typography>
                                                          &#47;&#47;
                                                          <Typography
                                                              sx={{display: 'inline'}}
                                                              component="span"
                                                              variant="body2"
                                                              color="text.primary"
                                                          > {inK(fond.latestChangePercentage * 1_000, true, 1, ' %')} </Typography>
                                                      </React.Fragment>
                                                  }
                                    />

                                    {isOpen === i ? <ExpandLess/> : <ExpandMore/>}
                                </ListItemButton>

                                <Collapse in={isOpen === i} timeout="auto" unmountOnExit>
                                    <List dense>
                                        <ListItem>
                                            <ListItemText primary="Current value"/>
                                            <ListItemText
                                                sx={{display: 'flex', justifyContent: 'flex-end'}}
                                                primary={inK(lastInArray(fond.values), false, 1, ' K')}/>
                                        </ListItem>

                                        <ListItem>
                                            <ListItemText primary="Invested"/>
                                            <ListItemText
                                                sx={{display: 'flex', justifyContent: 'flex-end'}}
                                                primary={inK(fond.invested, false, 1, ' K')}/>
                                        </ListItem>

                                        <ListItem>
                                            <ListItemText primary="ROI €"/>
                                            <ListItemText
                                                sx={{display: 'flex', justifyContent: 'flex-end'}}
                                                primary={inK(fond.roi_euro, true, 1, ' K')}/>
                                        </ListItem>

                                        <ListItem>
                                            <ListItemText primary="ROI %"/>
                                            <ListItemText
                                                sx={{display: 'flex', justifyContent: 'flex-end'}}
                                                primary={inK(fond.roi_percentage, true, 1, ' %')}/>
                                        </ListItem>
                                    </List>
                                </Collapse>
                            </div>
                        )
                    }
                )}
            </List>
        </>
    );
};

export default FondsSummary;

import React, {useState} from 'react';
import ReactECharts from "echarts-for-react";
import {inK} from "../../helper.functions";
import {Box, Divider, FormControlLabel, Switch, useMediaQuery} from "@mui/material";
import {grey} from "@mui/material/colors";

const WealthCalendar = ({wealth}) => {
    let groupedData = wealth.filter(item => item.assetsId !== 3).reduce((acc, curr) => {
        const {value, date} = curr;
        const existingArrIndex = acc.findIndex(arr => arr[0] === date);
        if (existingArrIndex !== -1) {
            acc[existingArrIndex][1] += value;
        } else {
            acc.push([date, value]);
        }
        return acc;
    }, []);

    groupedData.sort((a, b) => new Date(a[0]) - new Date(b[0]));

    const [showChangesPerDay, setShowChangesPerDay] = useState(true);

    if (showChangesPerDay) {
        groupedData = groupedData.length <= 1 ? [] : groupedData.slice(1).map((item, index) => {
            const previousValue = groupedData[index][1];
            const difference = item[1] - previousValue;
            return [item[0], difference];
        });
    }

    const percentColors = [
        {pct: 0.0, color: {r: 255, g: 0, b: 0}}, /* red */
        {pct: 0.5, color: {r: 255, g: 255, b: 0}}, /* yellow */
        {pct: 1.0, color: {r: 0, g: 255, b: 0}} /* green */
    ];

    const getColorForPercentage = function (pct) {
        let i;
        for (i = 1; i < percentColors.length - 1; i++) {
            if (pct < percentColors[i].pct) {
                break;
            }
        }
        const lower = percentColors[i - 1];
        const upper = percentColors[i];
        const range = upper.pct - lower.pct;
        const rangePct = (pct - lower.pct) / range;
        const pctLower = 1 - rangePct;
        const pctUpper = rangePct;
        const color = {
            r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
            g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
            b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
        };
        return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
    };

    const arrayLength = useMediaQuery((theme) => theme.breakpoints.up('sm')) ? 5 : 3; // 3 on mobile, 5 on desktop
    const colors = Array.from({length: arrayLength}, (_, index) => getColorForPercentage(index / arrayLength));

    const calculateVisualMapPieces = (min, max, parts) => {

        const step = (max - min) / parts;
        const pieces = [];
        for (let i = 0; i < parts; i++) {
            const currentMin = min + (step * i);
            const currentMax = min + (step * (i + 1));
            pieces.push({
                min: currentMin,
                max: currentMax,
                label: inK(currentMin, false, 1, ' - ') + inK(currentMax, false, 1, 'K'),
                color: colors[i]
            });
        }
        return pieces;
    }

    let options = {
        title: null,
        tooltip: {
            trigger: 'item',
            formatter: function (params) {
                const date = params.value[0];
                const value = params.value[1];
                return `${date}: <strong>${inK(value, showChangesPerDay, 1, 'K')}</strong>`;
            }
        },
        visualMap: {
            min: Math.min(...groupedData.map(item => item[1])),
            max: Math.max(...groupedData.map(item => item[1])),
            type: 'piecewise',
            orient: 'horizontal',
            left: 'center',
            top: 0,
            formatter: (value, value2) => {
                return inK(value, false, 0, ' - ') + inK(value2, false, 0, 'K')
            },
            color: colors, // Set colors for the visual map
            pieces: calculateVisualMapPieces(
                Math.min(...groupedData.map(item => item[1])),
                Math.max(...groupedData.map(item => item[1])),
                colors.length
            ), // Calculate the visual map pieces dynamically
        },
        calendar: {
            top: 80,
            left: 30,
            right: 5,
            orient: 'vertical',
            cellSize: ['auto', 'auto'],
            range: [
                groupedData[0][0].substring(0, 70),
                groupedData[groupedData.length - 1][0].substring(0, 70)
            ], itemStyle: {
                borderColor: grey[200], // Set border color to white
                borderWidth: groupedData.length > 100 ? 1 : 2, // Set border width to 1px
            },
            borderColor: '#F00', // Set border color for the complete calendar
            splitLine: {
                lineStyle: {
                    width: 2,
                    color: grey[600] // This will change the border color
                }
            },
            yearLabel: {show: false},
            dayLabel: {
                firstDay: 1 // start on Monday
            },
            textStyle: {
                rich: {
                    value: {
                        color: 'black'
                    },
                    marker: {
                        color: 'green',
                        align: 'center'
                    }
                }
            }
        },
        series: {
            type: 'heatmap',
            coordinateSystem: 'calendar',
            data: groupedData,
            label: {
                show: showChangesPerDay,
                formatter: function (params) {
                    return params.data[1] > 0 ? '+' : '';
                },
                color: '#000'
            },
        }
    };
    const toggleRelative = (event) => {
        setShowChangesPerDay(event.target.checked);
    };

    return (<div>

        <ReactECharts
            option={options}
            notMerge={true}
            lazyUpdate={true}
            style={{'height': '480px'}}
        />

        <Divider sx={{my: 2}}></Divider>

        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <FormControlLabel control={
                <Switch
                    checked={showChangesPerDay}
                    onChange={toggleRelative}
                />} label="Show per day"/>
        </Box>

    </div>)

};

export default WealthCalendar;
